/* Global color */
* {
  --form-text-color: #080a0c;
  --form-text-error-color: #e21a1e;
  --form-text-title-color: #154dd1;
  --form-text-success-color: #193f99;
  --form-text-info-color: #323a48;
  --form-arrow-color: #747474;
  --form-background-info-color: #f7f9ff;
  --form-background-box-color: #e8edfa;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.grecaptcha-badge {
  visibility: hidden;
}

p.captcha-info,
p.captcha-info a {
  font-size: 14px !important;
  color: #81909d;
  text-align: center;
  margin-top: 13px !important;
}

p.captcha-info a {
  text-decoration: underline;
}